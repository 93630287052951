// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import '../stylesheets/application'
import './bootstrap_custom.js'
require("jquery")
require("jquery-ui")
import "@fortawesome/fontawesome-free/js/all";


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$( document ).on('turbolinks:load', function() {

    $("#search_result").on("click",".change_amount", function( event ){
        event.preventDefault();
        let $el = $(this);
        let stock_id = $el.data("stockId");
        let type = $el.data("type");
        let sel = "#amount_" + stock_id.id;
        let amount = parseInt($(sel).html());

        if (type=='up') {
            amount = amount + 1;
        } else {
            amount = amount - 1;
            if (amount < 0) {
                amount = 0;
            }
        }

        let data = { "stock_id": stock_id.id,
                       "amount": amount};

          $.ajax({
            type: "POST",
            url: '/stocklevel',
            data: data,
            success: function (msg)
            {
              // console.log(msg);
            },
            error: function (xhr, status)
            {
              // console.log(xhr.error);
            },
            dataType: 'json'
          });

        
        $(sel).html(amount);
    });

    $("#search").each(function() {
        let $el = $(this);
        let type = $el.data("type");
        let url = type + ".json";
        let quantity = $el.data("quantity");
        $el.autocomplete({
            source: function (request, response) {
                
                $.ajax({
                    type:"GET",
                    url: url,
                    dataType:"json",
                    data: {term: request.term},
                    success:function(response){
                        let count = 0;
                        for (var key in response) {
                            if (response.hasOwnProperty(key)) {
                                if (url == 'shopstocks.json') {
                                    $('#search_result > tbody tr')
                                        .eq(count)
                                        .html("<td>" + response[key]['title'] + "</td>" +
                                              "<td>" + response[key]['sku'] + "</td>" + 
                                              "<td id='amount_" + response[key]['id'] + "'>" + response[key]['amount'] + "</td>" +
                                              "<td><a class='change_amount' data-stock-id='{&quot;id&quot;:" + response[key]['id'] + "}' data-type='up' href='#'>+</a>" +
                                              "<td><a class='change_amount' data-stock-id='{&quot;id&quot;:" + response[key]['id'] + "}' data-type='down' href='#'>-</a>" +
                                              "<td><a href='/" + type + "/" + response[key]['id'] + "/edit'>Edit</a>" +
                                              "<td><a data-confirm='Are you sure?' rel='nofollow' data-method='delete' href='/" + type + "/" + response[key]['id'] + "'>Delete</a></td>");
                                }

                                if ((url == 'fourdowns.json') || (url == 'seventies.json')){
                                    $('#search_result > tbody tr')
                                        .eq(count)
                                        .html("<td>" + response[key]['title'] + "</td>" +
                                              "<td>" + response[key]['sku'] + "</td>" + 
                                              "<td>" + response[key]['amount'] + "</td>");
                                }
                                if (url == 'webstores.json') {
                                    $('#search_result > tbody tr')
                                        .eq(count)
                                        .html("<td>" + response[key]['title'] + "</td>" +
                                              "<td>" + response[key]['vendor'] + "</td>" +
                                              "<td>" + response[key]['option'] + "</td>" +
                                              "<td>" + response[key]['sku'] + "</td>" + 
                                              "<td>" + response[key]['amount'] + "</td>");
                                }

                                count++;
                            };
                        };
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                      console.log(xhr.status);
                      console.log(thrownError);
                    }
                  });
              }
        });

    });

});

